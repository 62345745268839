import { Cloudinary } from 'cloudinary-core';

export const cloudinary = new Cloudinary({
    cloud_name: 'grant-murray-architects',
    api_key: '175236942213696',
});

export const generateUrl = (image, width = 1200) =>
    cloudinary.url(image, {
        secure: true,
        transformation: { width, crop: 'scale' },
    });
