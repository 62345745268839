import styled, { css } from 'styled-components';

import breakPoints from '../helpers/breakPoints';
import { generateUrl } from '../helpers/cloudinary';

export const Grid = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

    ::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
    }

    > *:first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
    }

    @media (max-width: ${breakPoints.portrait - 1}px) {
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(9, 50vh);
    }

    @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet - 1}px) {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, minmax(15rem, 1fr));
    }

    @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen - 1}px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd - 1}px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(7, 1fr);
    }

    @media (min-width: ${breakPoints.fullhd}px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(7, 1fr);
    }
`;

export const ProjectTypeGridItem = styled.div`
    position: relative;
    background: rgba(0, 0, 0, 0.1);
    background-size: cover;
    margin: 0.5rem;
    padding: 0.5rem;
    transition: all 0.6s ease;
    background-color: ${(props) => props.color}};

    > h4 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid black;
        padding: 3px;
        color: rgba(46, 49, 49, 1);
        font-family: TwCenMTStd, Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 6px;
        font-size: 0.9rem;
        white-space: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
        text-align: center;

        @media (max-width: ${breakPoints.portrait}px) {
            font-size: 1.9rem;
        }

        @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet - 1}px) {
            font-size: 1.4rem;
        }

        @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen - 1}px) {
            font-size: 1rem;
        }

        @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd - 1}px) {
            font-size: 0.9rem;
        }

        @media (min-width: ${breakPoints.fullhd}px) {
            font-size: 1rem;
        }
    }

    > div {
        width: 100%;
        height: 100%;
        background-color: white;
    }
`;

export const GridItem = styled.div`
    position: relative;
    background: rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center;
    margin: 0.5rem;
    padding: 0.5rem;
    transition: all 0.6s ease;

    visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};

    > h4 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid black;
        padding: 3px;
        color: rgba(46, 49, 49, 1);
        font-family: TwCenMTStd, Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 6px;
        font-size: 0.9rem;
        white-space: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
        text-align: center;

        @media (max-width: ${breakPoints.portrait}px) {
            font-size: 1.9rem;
        }

        @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet - 1}px) {
            font-size: 1.4rem;
        }

        @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen - 1}px) {
            font-size: 1rem;
        }

        @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd - 1}px) {
            font-size: 0.9rem;
        }

        @media (min-width: ${breakPoints.fullhd}px) {
            font-size: 1rem;
        }
    }

    > div {
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.4;
    }

    ${(props) =>
        props.triangle === 'top-left' &&
        css`
            clip-path: polygon(0 0, 0% 100%, 100% 0);
        `}

    ${(props) =>
        props.triangle === 'bottom-right' &&
        css`
            clip-path: polygon(100% 0, 0 100%, 100% 100%);
        `}

    ${(props) =>
        props.triangle === 'right' &&
        css`
            clip-path: polygon(0 0, 0 100%, 100% 100%);
        `}

    ${(props) =>
        props.triangle === 'left' &&
        css`
            clip-path: polygon(0 0, 100% 100%, 100% 0);
        `}

    ${(props) =>
        props.to &&
        css`
            :hover {
                transform: scale(1.08);
            }
        `}

    ${(props) =>
        props.image &&
        css`
            @media (max-width: ${breakPoints.portrait}px) {
                background-image: url(${generateUrl(props.image, 1200)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet - 1}px) {
                background-image: url(${generateUrl(props.image, 1200)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen - 1}px) {
                background-image: url(${generateUrl(props.image, 1200)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd - 1}px) {
                background-image: url(${generateUrl(props.image, 1200)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.fullhd}px) {
                background-image: url(${generateUrl(props.image, 1200)});
                background-size: cover;
            }
        `}
`;

export const GridContainer = styled.div`
    max-width: 95%;
    margin: auto;

    @media (min-width: ${breakPoints.tablet}px) {
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }
`;
