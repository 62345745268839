import React from 'react';
import styled, { css } from 'styled-components';

import breakPoints from '../helpers/breakPoints';
import { generateUrl } from '../helpers/cloudinary';

const ProjectGrid = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

    @media (max-width: ${breakPoints.portrait}px) {
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(
            ${(props) => props.projects.length + 1},
            ${(props) => (props.viewHeight ? props.viewHeight : '50vh')}
        );
    }

    @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet - 1}px) {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, minmax(15rem, 1fr));
    }

    @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen - 1}px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd - 1}px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(7, 1fr);
    }

    @media (min-width: ${breakPoints.fullhd}px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(7, 1fr);
    }

    ::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
    }

    > *:first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
    }
`;

export const ProjectGridItem = styled.div`
    position: relative;
    background: rgba(0, 0, 0, 0.1);
    background-size: cover;
    margin: 0.5rem;
    padding: 0.5rem;

    transition: all 2s ease;

    > h4 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid black;
        padding: 3px;
        color: rgba(46, 49, 49, 1);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    > div {
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.4;
    }

    ${(props) =>
        props.to &&
        css`
            :hover {
                background-color: white;
                background-image: none;
            }
        `}

    ${(props) =>
        props.image &&
        css`
            @media (max-width: ${breakPoints.portrait}px) {
                background-image: url(${generateUrl(props.image, 250)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet}px) {
                background-image: url(${generateUrl(props.image, 250)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen}px) {
                background-image: url(${generateUrl(props.image, 250)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd}px) {
                background-image: url(${generateUrl(props.image, 250)});
                background-size: cover;
            }

            @media (min-width: ${breakPoints.fullhd}px) {
                background-image: url(${generateUrl(props.image, 250)});
                background-size: cover;
            }
        `}
`;

export const ProjectTypeGrid = ({ viewHeight, projects, children }) => (
    <ProjectGrid viewHeight={viewHeight} projects={projects}>
        {children}
    </ProjectGrid>
);
