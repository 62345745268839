import React from 'react';
import styled from 'styled-components';

import breakPoints from '../helpers/breakPoints';
import instagram from '../img/instagram.svg';
import linkedin from '../img/linkedin.svg';

const StyledFooter = styled.footer`
    font-size: 0.9rem;
    letter-spacing: 0;
    display: flex;
    justify-content: flex-end;
    a {
        img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
        }
    }

    @media (max-width: ${breakPoints.tablet}px) {
        padding: 30px;
        text-align: left;
        flex-direction: column;
        div {
            display: flex;
            justify-content: center;
            margin-bottom: 0.5rem;
            a {
                color: red;
                flex-wrap: wrap;
                img {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }

    @media (min-width: ${breakPoints.tablet}px) {
        text-align: right;
    }
`;

const Footer = ({ style }) => (
    <StyledFooter style={style}>
        <div>
            <a
                title="instagram"
                href="https://instagram.com/grantmurrayarchitects"
                rel="noopener noreferrer"
                target="_blank"
            >
                <img src={instagram} alt="Instagram" />
            </a>
            <a
                title="linked"
                href="https://www.linkedin.com/company/grant-murray-architects"
                rel="noopener noreferrer"
                target="_blank"
            >
                <img src={linkedin} alt="linkedin" />
            </a>
        </div>
        Ⓒ Copyright {new Date().getFullYear()} Grant Murray Architects Ltd. All Rights reserved | Company No: SC374492
    </StyledFooter>
);

export default Footer;
